<template>
	<div
		:id="`rte-${_uid}`"
		:class="[
			'c-block-list-rte-custom',
			'w-5col <1024:w-full >=768:w-6col max-w-full mx-auto',
		]"
	>
		<LongReadTarget
			v-if="title"
			:container-id="`rte-${_uid}`"
			:title="title"
			:disabled="Boolean(accordionNestingLevel)"
		>
			<BaseH2 class="text-primary-button mb-sm" v-text="title" />
		</LongReadTarget>

		<div v-if="text" class="s-rich-text" v-html="text"></div>
	</div>
</template>

<script>
import LongReadTarget from '~/citi-baseline/components/LongReadTarget';

export default {
	name: 'BlockListRteCustom',
	components: { LongReadTarget },

	inject: {
		accordionNestingLevel: {
			default: null,
		},
	},

	inheritAttrs: false,

	props: {
		text: String,
		title: String,
	},
};
</script>
